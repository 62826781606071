//colors
.yellow {
  color: #fdab29;
}
.black {
  color: #0A0A0A
}
.white {
  color: white;
}
.dark-blue {
  color: #3C1E93;
}
.whatsapp-green {
  color: #06D91B;
}
.text-color {
  color: #0A0A0A;
}

//solid backgrounds
.bg-yellow {
  background-color: #fdab29;
}
.bg-light-yellow {
  background-color: #ffe29b;
}
.bg-white {
  background-color: white;
}
.bg-dark-blue {
  background-color: #4247D3;
}
.bg-darker-blue {
  background-color: #2d2b95;
}
.bg-light-blue {
  background-color: #d6f1fa;
}
.bg-light-dark-blue {
  background-color: #aed5e3;
}
.bg-presspage-green {
  background-color: #8bf0b0;
}
.bg-presspage-green--darker {
  background-color: #12B754;
}
.bg-red {
  background-color: #FAA195;
}
.bg-red--darker {
  background-color: #F87B6B;
}
.bg-grey {
  background-color: #F5F5F5;
}
.bg-whatsapp-green {
  background-color: #06D91B;
}

.bg-text-color {
  background-color: #0A0A0A;
}

//gradient backgrounds
.bg-gradient-yellow {
  background-color: #fdab29;
}

//hover backgrounds
.hover-dark-blue {
  &:hover {
    background-color: darken(#4247D3, 5%);
  }
}

.hover-darker-blue {
  &:hover {
    background-color: darken(#2d2b95, 5%);
  }
}

.hover-light-dark-blue {
  &:hover {
    background-color: darken(#aed5e3, 5%);
  }
}

.bg-presspage-green--darker {
  &:hover {
    background-color: darken(#12B754, 5%);
  }
}

.bg-red--darker {
  &:hover {
    background-color: darken(#f87b6b, 5%);
  }
}