//section styling
.section {
  max-width: 1280px;
  position: relative;
  margin: auto;
  box-sizing: border-box;
  padding: 0 4rem;
  @media screen and (max-width: 1550px) {
    max-width: 1024px;
  }
  @media screen and (max-width: 1280px) {
    max-width: 860px;
  }
  @media screen and (max-width: 600px) {
    padding: 0 20px;
  }
}

.section.banner {
  @media screen and (max-width: 1280px) {
    padding-bottom: 6rem;
  }
}

.section.results {
  max-width: 100%;
}

.header-text {
  max-width: 450px;
  margin-top: $space6;
}

.header-text.banner {
  @media screen and (max-width: 1550px) {
    margin-top: 0;
  }
  @media screen and (max-width: 1280px) {
    margin-top: -2rem;
  }
}

.head-image.banner {
  @media screen and (max-width: 1550px) {
    margin-top: -4rem;
  }
}


//heights
.vh-75 {
  height: 75vh;
}

.vh-100 {
  height: 100vh;
}

.h-100 {
  height: 100%;
}

.h1 {
  height: $space1;
}

//widths
.w1 {
  width: $space1;
}
.w2 {
  width: $space2;
}
.w3 {
  width: $space3;
}
.w4 {
  width: $space4;
}
.w5 {
  width: $space5;
}
.w6 {
  width: $space6;
}

.w-20 {
  width: 20%;
}

.w-50 {
  width: 50%;
}

.w-80 {
  width: 80%;
}

.w-100 {
  width: 100%;
}

.mw-100 {
  max-width: 100%;
}

.col-1-3 {
  width: 33.33%;
}

.col-2-3 {
  width: 67.67%;
}

.col-1-2 {
  width: 50%;
}

.article-width {
  max-width: 900px;
}

//padding
.p0 {
  padding: 0;
}
.p1 {
  padding: $space1;
}
.p2 {
  padding: $space2;
}
.p3 {
  padding: $space3;
}
.p4 {
  padding: $space4;
}
.p5 {
  padding: $space5;
}
.p6 {
  padding: $space6;
}

.pt0 {
  padding-top: 0;
}
.pt1 {
  padding-top: $space1;
}
.pt2 {
  padding-top: $space2;
}
.pt3 {
  padding-top: $space3;
}
.pt4 {
  padding-top: $space4;
}
.pt5 {
  padding-top: $space5;
}
.pt6 {
  padding-top: $space6;
}

.pb0 {
  padding-bottom: 0;
}
.pb1 {
  padding-bottom: $space1;
}
.pb2 {
  padding-bottom: $space2;
}
.pb3 {
  padding-bottom: $space3;
}
.pb4 {
  padding-bottom: $space4;
}
.pb5 {
  padding-bottom: $space5;
}
.pb6 {
  padding-bottom: $space6;
}

.pl0 {
  padding-left: 0;
}
.pl1 {
  padding-left: $space1;
}
.pl2 {
  padding-left: $space2;
}
.pl3 {
  padding-left: $space3;
}
.pl4 {
  padding-left: $space4;
}
.pl5 {
  padding-left: $space5;
}
.pl6 {
  padding-left: $space6;
}

//margin
.m0 {
  margin: 0;
}

.m-auto {
  margin: auto;
}
.m1 {
  margin: $space1;
}
.m2 {
  margin: $space2;
}
.m3 {
  margin: $space3;
}
.m4 {
  margin: $space4;
}
.m5 {
  margin: $space5;
}
.m6 {
  margin: $space6;
}

.mt0 {
  margin-top: 0;
}
.mt1 {
  margin-top: $space1;
}
.mt2 {
  margin-top: $space2;
}
.mt3 {
  margin-top: $space3;
}
.mt4 {
  margin-top: $space4;
}
.mt5 {
  margin-top: $space5;
}
.mt6 {
  margin-top: $space6;
}

.mb0 {
  margin-bottom: 0;
}
.mb0-5 {
  margin-bottom: 0.5rem;
}
.mb1 {
  margin-bottom: $space1;
}
.mb2 {
  margin-bottom: $space2;
}
.mb3 {
  margin-bottom: $space3;
}
.mb4 {
  margin-bottom: $space4;
}
.mb5 {
  margin-bottom: $space5;
}
.mb6 {
  margin-bottom: $space6;
}

.ml0 {
  margin-left: 0;
}
.ml0-5 {
  margin-left: 0.5rem;
}
.ml1 {
  margin-left: $space1;
}
.ml2 {
  margin-left: $space2;
}
.ml3 {
  margin-left: $space3;
}
.ml4 {
  margin-left: $space4;
}
.ml5 {
  margin-left: $space5;
}
.ml6 {
  margin-left: $space6;
}

.mr0 {
  margin-right: 0;
}
.mr0-5 {
  margin-right: 0.5rem;
}
.mr1 {
  margin-right: $space1;
}
.mr2 {
  margin-right: $space2;
}
.mr3 {
  margin-right: $space3;
}
.mr4 {
  margin-right: $space4;
}
.mr5 {
  margin-right: $space5;
}
.mr6 {
  margin-right: $space6;
}

.mlauto {
  margin-left: auto;
}

//displays
.inline-block {
  display: inline-block;
}
.block {
  display: block;
}
.inline {
  display: inline;
}
.hide {
  display: none;
}

//positions
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}

.bottom-1 {
  bottom: 1rem;
}

.bottom-2 {
  bottom: 2rem;
}

.right-1 {
  right: 1rem;
}

.top-1 {
  top: 1rem;
}

//
.horizontal-center {
  margin-left: auto;
  margin-right: auto;
}

.right {
  float: right;
}

.left {
  float: left;
}

.none {
  float: none;
}

.overflow-hidden {
  overflow: hidden;
}

.hide {
  display: none;
}

p {
  margin-top: 0;
  line-height: 1.6;
}