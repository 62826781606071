.shadow-big {
  -webkit-box-shadow: 0px 2px 40px 0px rgba(10,10,10,0.12);
  -moz-box-shadow: 0px 2px 40px 0px rgba(10,10,10,0.12);
  box-shadow: 0px 2px 40px 0px rgba(10,10,10,0.12);
}

.shadow-small {
  -webkit-box-shadow: 0px 2px 20px 0px rgba(10,10,10,0.12);
  -moz-box-shadow: 0px 2px 20px 0px rgba(10,10,10,0.12);
  box-shadow: 0px 2px 20px 0px rgba(10,10,10,0.12);
}