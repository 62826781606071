.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.flex-align-center {
  align-items: center;
}
.space-between {
  justify-content: space-between;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex1 {
  flex: 1;
}
.flex2 {
  flex: 2;
}
.flex3 {
  flex: 3;
}
.flex-25 {
  width: calc(25% - 1rem);
}

.flex-col-1-3 {
  width: 33.3%;
}

.flex-col-1-3.grid {
  width: 32.3%;
}

.flex-col-1-2.grid {
  width: 48.5%;
}