body, html {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #0A0A0A;
  margin: 0;
  padding: 0;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}

* {
  box-sizing: border-box;
}

//spacing
$space0-5: 0.5rem;
$space0-75: 0.75rem;
$space1: 1rem;
$space1-5: 1.5rem;
$space2: 2rem;
$space3: 3rem;
$space4: 4rem;
$space5: 5rem;
$space6: 6rem;