.decoration-none {
  text-decoration: none;
}

.list-style-none {
  list-style: none;
}

.br10 {
  border-radius: 10px;
}

.card {
  -webkit-box-shadow: 0px 2px 40px 0px rgba(10,10,10,0.12);
  -moz-box-shadow: 0px 2px 40px 0px rgba(10,10,10,0.12);
  box-shadow: 0px 2px 40px 0px rgba(10,10,10,0.12);
  border-radius: 10px;
  margin: $space0-5;
}

.social-icon {
  width: 33px;
  height: 33px;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.social-icon.big {
  width: 60px;
  height: 60px;
}

.border-white {
  border: 1px solid white;
}

.border-bottom-transparent {
  border-bottom: 1px solid transparent;
}

.border-bottom-black {
  border-bottom: 1px solid #0A0A0A;
}

.border-bottom-white {
  border-bottom: 1px solid white;
}

.font-weight-bold {
  font-weight: 700;
}

.hover-border-bottom-black {
  &:hover {
    border-bottom: 1px solid #0A0A0A;
  }
}

.hover-border-bottom-white {
  &:hover {
    border-bottom: 1px solid white;
  }
}

.external-link {
  position: relative;
  text-decoration: none;
  padding-right: 30px;
  &::after {
    content: '';
    position: absolute;
    display: inline-block;
    right: 0;
    padding-top: 3px;
    width: 18px;
    height: 18px;
    background-image: url(../assets/icons/external-icon.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  &:hover {
    text-decoration: underline;
  }
}

.external-link-black {
  position: relative;
  text-decoration: none;
  padding-right: 30px;
  &::after {
    content: '';
    position: absolute;
    display: inline-block;
    right: 0;
    padding-top: 3px;
    width: 18px;
    height: 18px;
    background-image: url(../assets/icons/external-icon-black.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  &:hover {
    text-decoration: underline;
  }
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}