@media screen and (max-width:1280px) {
  .l-col-1-2 {
    width: 47%;
  }

  .l-flex-wrap {
    flex-wrap: wrap;
  }

  .l-ml0 {
    margin-left: 0;
  }

  .l-pb0 {
    padding-bottom: 0;
  }

  .l-pr1 {
    padding-right: 1rem;
  }

  .l-f-sm {
    font-size: 14px;
  }
}

@media screen and (max-width: 950px) {
  //width
  .m-w-100 {
    width: 100%;
  }

  //margin
  .m-mt2 {
    margin-top: 2rem;
  }

  .m-mb3 {
    margin-bottom: 3rem;
  }

  .m-ml1 {
    margin-left: 1rem;
  }

  .m-ml0 {
    margin-left: 0;
  }

  //padding
  .m-pl1 {
    padding-left: 1rem;
  }
  .m-pr1 {
    padding-right: 1rem;
  }
  .m-pl2 {
    padding-left: 2rem;
  }
  .m-pr2 {
    padding-right: 2rem;
  }

  //floats
  .m-float-none {
    float: none;
  }

  //layout
  .m-inline-block {
    display: inline-block;
  }
  .m-hide {
    display: none;
  }

  //flex
  .m-flex-wrap {
    flex-wrap: wrap;
  }

  //typography
  .m-heading1 {
    font-size: 32px;
  }

  .m-heading2 {
    font-size: 18px;
  }

  .m-heading3 {
    font-size: 16px;
  }

  .m-heading5 {
    font-size: 14px;
  }

  .m-subheading-header {
    font-size: 18px;
  }

  .m-summary-text {
    font-size: 18px;
  }

  .m-f-sm {
    font-size: 14px;
  }

  .m-tc {
    text-align: center;
  }

  .m-relative {
    position: relative;
  }

  .m-block {
    display: block;
  }

  .m-col-1-2 {
    width: 47%;
  }

  .m-col-1-2.grid {
    width: 48.5%;
  }

  .m-p0 {
    padding: 0;
  }

  .m-pl0 {
    padding-left: 0;
  }
  .m-pl1 {
    padding-left: $space1;
  }
  .m-pl2 {
    padding-left: $space2;
  }
  .m-pl3 {
    padding-left: $space3;
  }
  .m-pl4 {
    padding-left: $space4;
  }
  .m-pl5 {
    padding-left: $space5;
  }
  .m-pl6 {
    padding-left: $space6;
  }

  .m-pr0 {
    padding-left: 0;
  }
  .m-pr1 {
    padding-left: $space1;
  }
  .m-pr2 {
    padding-left: $space2;
  }
  .m-pr3 {
    padding-left: $space3;
  }
  .m-pr4 {
    padding-left: $space4;
  }
  .m-pr5 {
    padding-left: $space5;
  }
  .m-pr6 {
    padding-left: $space6;
  }

  .m-mr0 {
    margin-right: 0;
  }
  .m-ml0 {
    margin-left: 0;
  }
}

@media screen and (max-width: 600px) {
  .sm-col-1-1 {
    width: 100%;
  }
  .sm-col-1-1.grid {
    width: 100%!important;
  }

  .sm-width-100 {
    width: 100%!important;
  }

  .sm-ml0 {
    margin-left: 0;
  }

  .sm-mr0 {
    margin-right: 0;
  }

  .sm-mb1 {
    margin-bottom: 1rem;
  }

  .sm-p0 {
    padding: 0;
  }

  .sm-p1 {
    padding: $space1;
  }

  .sm-p2 {
    padding: $space2;
  }

  .sm-pl0 {
    padding-left: 0;
  }
  .sm-pl1 {
    padding-left: $space1;
  }
  .sm-pl2 {
    padding-left: $space2;
  }
  .sm-pl3 {
    padding-left: $space3;
  }
  .sm-pl4 {
    padding-left: $space4;
  }
  .sm-pl5 {
    padding-left: $space5;
  }
  .sm-pl6 {
    padding-left: $space6;
  }

  .sm-pr0 {
    padding-right: 0;
  }
  .sm-pr1 {
    padding-right: $space1;
  }
  .sm-pr2 {
    padding-right: $space2;
  }
  .sm-pr3 {
    padding-right: $space3;
  }
  .sm-pr4 {
    padding-right: $space4;
  }
  .sm-pr5 {
    padding-right: $space5;
  }
  .sm-pr6 {
    padding-right: $space6;
  }

  .sm-block {
    display: block;
  }

  .sm-display-none {
    display: none;
  }

  .sm-inline-block {
    display: inline-block;
  }

  .sm-summary-text {
    font-weight: 300;
    font-size: 1rem;
    font-family: 'Open Sans', sans-serif;
  }

  .sm-bg-white {
    background-color: white;
  }

  .sm-heading3 {
    font-size: 24px;
  }
}

@media screen and (max-height: 900px) {

}