//font-styles
.heading1 {
  font-family: 'Merriweather', serif;
  font-size: 40px;
  font-weight: 700;
  @media screen and (max-width: 1280px) {
    font-size: 32px;
  }
}

.heading2 {
  font-family: 'Merriweather', serif;
  font-size: 24px;
  font-weight: 700;
  @media screen and (max-width: 1280px) {
    font-size: 18px;
  }
}

.heading3 {
  font-family: 'Merriweather', serif;
  font-size: 18px;
  font-weight: 700;
  @media screen and (max-width: 1280px) {
    font-size: 16px;
  }
}

.heading5 {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 700;
  @media screen and (max-width: 1280px) {
    font-size: 14px;
  }
}

.subheading-header {
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  font-weight: 300;
  @media screen and (max-width: 1280px) {
    font-size: 18px;
  }
}

.summary-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  font-weight: 300;
  @media screen and (max-width: 1280px) {
    font-size: 18px;
  }
}

.f-sm {
  font-size: 14px;
}

.tc {
  text-align: center;
}

.font-button {
  font-size: 18px;
}