.social-icon .fab, .social-icon .fas {
  color: white;
  font-size: 22px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.whatsapp {
  .social-icon, .graph-bar {
    background-color: #06D91B;
  }
}
.snapchat {
  .social-icon, .graph-bar {
    background-color: #FDC706;
  }
}
.instagram {
  .social-icon, .graph-bar {
    background-color: #FD6E06;
  }
}
.twitter {
  .social-icon, .graph-bar {
    background-color: #1BC8EE;
  }
}
.facebook {
  .social-icon, .graph-bar {
    background-color: #3C1E93;
  }
}
.no-social {
  .social-icon, .graph-bar {
    background-color: #931E2C;
  }
}

.photoshop {
  background-color: blue;
  background-image: url('../assets/icons/photoshop.svg');
}

.illustrator {
  background-color: blue;
  background-image: url('../assets/icons/illustrator.svg');
}

.marvelapp {
  background-color: blue;
  background-image: url('../assets/icons/marvel-app.png');
}

.invision {
  background-color: white;
  background-image: url('../assets/icons/invision.png');
}

.figma {
  background-color: #353535;
  background-size: 40%;
  background-image: url("../assets/icons/figma.svg"); }

.sketch {
  background-color: #353535;
  background-size: 55%;
  background-image: url("../assets/icons/sketch.png"); }

.axure {
  background-color: white;
  border: 1px solid lightgray;
  background-size: 55%;
  background-image: url("../assets/icons/Axure-logo.webp"); }

.zbrush {
  background-color: white;
  border: 1px solid lightgray;
  background-size: 60%;
  background-image: url("../assets/icons/zbrush.png"); }

.maya {
  background-color: white;
  border: 1px solid lightgray;
  background-size: 60%;
  background-image: url("../assets/icons/maya.jpg"); }

.substance {
  background-color: white;
  border: 1px solid lightgray;
  background-size: 90%;
  background-image: url("../assets/icons/substance.jpg"); }

.arnold {
  background-image: url("../assets/icons/arnold.png"); }

.yes {
  .graph-bar {
    background-color: #06D91B;
  }
}

.no {
  .graph-bar {
    background-color: #FDC706;
  }
}

.graph-bar-container {
  border-radius:50px;
  position: relative;
  height: 5px;
  overflow: hidden;
}

.graph-bar {
  height: 100%;
  position: absolute;
  display: inline-block;
  transform: translateX(-100%);
  transition: 0.5s transform cubic-bezier(0, 0, 0.28, 1);
}

.js-viewport-visible .graph-bar {
    transform: translateX(0);
}

.profile {
  width: 60px;
  height: 60px;
}

.profile-big {
  width: 120px;
  height: 120px;
}

.spacer {
  height: 20px;
  width: 75px;
  border-radius: 100px;
  background-color: #ececec;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.rounded-button {
  display: inline-block;
  border-radius: 50px;
  padding: $space0-75 $space1-5;
  box-sizing: border-box;
  clear: both;
  color: white;
  text-decoration: none;
  font-weight: 600;
}

.menu-small.js-menu-hidden {
  visibility: hidden;
}
.js-menu-hidden .menu-circle {
  transform: scale(0.5);
}
.menu-circle {
  transition: 0.2s all ease-out;
}

.menu-small {
  z-index: 999;
}

.case-text {
  z-index: 2;
}

.case-bg-image {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  @media screen and (max-width: 950px) , screen and (max-height: 700px) {
    position: relative;
    margin-top: $space3;
  }
}

.case-bg-image img {
  width: 65%;
  right: 0;
  top: 210px;
  @media screen and (max-width: 950px) {
    position: relative;
    top: 0!important;
  }
  @media screen and (max-height: 900px) {
    width: 50%;
    top: 150px;
  }
}

.homepage-banner-img {
  @media screen and (max-width: 1280px) {
    width: 65%;
  }
}

.carousel {
  height: 700px;
  overflow:hidden;
}
.carousel-tab .tab-item {
  padding-bottom: 0.5rem;
  transition: all ease-in-out 0.15s;
  cursor: pointer;
  &:hover {
    font-weight: 700;
  }
}
.carousel-tab .active {
  font-weight: 700;
  border-bottom: 2px solid white;
  opacity: 1;
}
.carousel-tab .black.active {
  border-bottom: 2px solid #0a0a0a;
}
.carousel-tab .tab-item-zoom {
  width:24px;
  height:24px;
  margin-left:auto;
  display:inline-block;
  cursor: pointer;
}
.icon-zoom-in {
  background-image: url('../assets/images/zoom-in.svg');
}
.icon-zoom-out {
  background-image: url('../assets/images/zoom-out.svg');
}
.carousel-navigation {
  .chevron::before {
    border-style: solid;
    border-width: 0.25em 0.25em 0 0;
    content: '';
    display: inline-block;
    height: 0.45em;
    left: 0.15em;
    position: relative;
    top: 0.15em;
    transform: rotate(-45deg);
    vertical-align: top;
    width: 0.45em;
  }
  .chevron.prev:before {
    left: 0.25em;
    transform: rotate(-135deg);
  }
  .chevron.next:before {
    left: 0;
    transform: rotate(45deg);
  }
  .prev, .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 24px;
    height: 24px;
    z-index:2;
  }
  .prev {
    left: $space2;
  }
  .next {
    right: $space2;
  }
}
.bg-light-blue .carousel-tab .active {
  border-bottom: 2px solid #0A0A0A;
}

.slider.bg-grey {
  scrollbar-width: thin;
  scrollbar-color: #f5f5f5;
  &::-webkit-scrollbar-track {
    background: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #f5f5f5 ;
    border-radius: 6px;
    border: 3px solid #f5f5f5;
  }
  .carousel-item:hover {
    scrollbar-color: #f5f5f5;
    &::-webkit-scrollbar-track {
      background: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #aed5e3 ;
      border: 3px solid #f5f5f5;
    }
  }
}

.slider {
  .carousel-item {
    position: absolute;
    bottom: 0;
    left: 0;
    transition: transform cubic-bezier(0.61,-0.01, 0.46, 1.09) 0.7s;
    height: 600px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 11px;
    }
  }
  .carousel-item:hover {
    &::-webkit-scrollbar {
      width: 11px;
    }
  }
}

.bg-light-blue {
  .carousel-item {
    position: absolute;
    bottom: 0;
    left: 0;
    transition: transform cubic-bezier(0.61, -0.01, 0.46, 1.09) 0.7s;
    height: 600px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #d6f1fa;
    &::-webkit-scrollbar {
      width: 11px;
    }
    &::-webkit-scrollbar-track {
      background: #d6f1fa;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #d6f1fa;
      border-radius: 6px;
      border: 3px solid #d6f1fa;
    }
  }
  .carousel-item:hover {
    scrollbar-color: #aed5e3;
    &::-webkit-scrollbar {
      width: 11px;
    }
    &::-webkit-scrollbar-track {
      background: #d6f1fa;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #aed5e3;
      border: 3px solid #aed5e3;
    }
  }
}

.bg-dark-blue {
  .carousel-item {
    position: absolute;
    bottom: 0;
    left: 0;
    transition: transform cubic-bezier(0.61,-0.01, 0.46, 1.09) 0.7s;
    height: 600px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbarBG) var(--scrollbarBG);
    &::-webkit-scrollbar {
      width: 11px;
    }
    &::-webkit-scrollbar-track {
      background: var(--scrollbarBG);
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--scrollbarBG) ;
      border-radius: 6px;
      border: 3px solid var(--scrollbarBG);
    }
  }
  .carousel-item:hover {
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
    &::-webkit-scrollbar {
      width: 11px;
    }
    &::-webkit-scrollbar-track {
      background: var(--scrollbarBG);
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--thumbBG) ;
      border: 3px solid var(--scrollbarBG);
    }
  }
}

.bg-darker-blue {
  .carousel-item {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 600px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #2d2b95;
    &::-webkit-scrollbar {
      width: 11px;
    }
    &::-webkit-scrollbar-track {
      background: #2d2b95;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #2d2b95;
      border-radius: 6px;
      border: 3px solid #2d2b95;
    }
  }
  .carousel-item:hover {
    scrollbar-color: #2d2b95;
    &::-webkit-scrollbar {
      width: 11px;
    }
    &::-webkit-scrollbar-track {
      background: #2d2b95;
    }
    &::-webkit-scrollbar-thumb {
      background-color: white;
      border: 3px solid #2d2b95;
    }
  }
}

.carousel.zoomed-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999999;
  .carousel-item {
    top: 0;
    height: 100vh;
    padding-top: 120px;
    z-index: -1;
  }
  .carousel-item.desktop-carousel img {
    max-width: 1280px!important;
  }
}
body.zoomed-in, html.zoomed-in {
  overflow: hidden;
}


html {
  --scrollbarBG: #4247D3;
  --thumbBG: white;
}

.carousel-item img {
  width: 95%;
}

.carousel-item.desktop {
  transform: translateX(-100%);
}

.carousel-item.desktop-carousel, .carousel-item.mobile-carousel {
  opacity: 0;
  transform: translateX(100%);
}

.carousel-item.mobile {
  transform: translateX(100%);
}

.carousel-item.sliding-away-left {
  opacity: 1;
  transform: translateX(-100%);
}

.carousel-item.sliding-away-right {
  opacity: 1;
  transform: translateX(100%);
}

.carousel-item.prev-active {
  transform: translateX(-100%);
  transition: none;
}

.carousel-item.active {
  opacity: 1;
  transform: translateX(0);
  transition: transform cubic-bezier(0.61,-0.01, 0.46, 1.09) 0.7s;
}

.back-button {
  cursor: pointer;
  user-select: none;
  margin-top: -6rem;
  img {
    width: 20px;
    display:inline-block;
    position: relative;
    top: 1px;
    padding-right: 3px;
  }
}

.infinite-up-down {
  animation: MoveUpDown 1.5s ease-in-out infinite;
  position: relative;
  transform: translateY(0);
}

@keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(7px);
  }
}

.hover-item {
  .bg-image, p {
    transition: opacity ease-in-out 0.3s;
  }
  &:hover {
    .bg-image {
      opacity: 0.8;
    }
    p {
      text-decoration: underline;
    }
  }
}

.toolkit-container {
  max-width: 415px;
  margin-left: -1rem;
}

.toolkit-item {
  width: 85px;
}

.menu-full, .footer {
  padding-left: 4rem;
  padding-right: 4rem;
  @media screen and (max-width: 600px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@keyframes showBannerImage {
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes showBannerText {
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes showBannerButton {
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.welcome-banner .homepage-banner-img, .js-viewport-hidden .homepage-banner-img {
  opacity: 0;
  transform: translateX(75px);
}
.welcome-banner .banner-title, .welcome-banner .banner-button, .js-viewport-hidden .banner-title, .js-viewport-hidden .banner-button {
  opacity: 0;
  transform: translateX(-75px);
}

.welcome-banner .homepage-banner-img.opacity-banner, .js-viewport-visible .homepage-banner-img {
  animation-delay: 0.65s;
  animation-name: showBannerImage;
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0, 1.02, 1, 1);
  animation-fill-mode: forwards;
}
.welcome-banner .banner-title.opacity-banner, .js-viewport-visible .banner-title {
  animation-name: showBannerText;
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0, 1.02, 1, 1);
  animation-fill-mode: forwards;
}
.welcome-banner .banner-button.opacity-banner, .js-viewport-visible .banner-button {
  animation-delay: 0.25s;
  animation-name: showBannerButton;
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0, 1.02, 1, 1);
  animation-fill-mode: forwards;
}

.oo-work-card {
  padding-top:100%;
  background-image: url('../assets/images/OA-header-img.jpg');
  background-size:160%;
  background-position:-150px center;
  background-repeat:no-repeat;
  @media screen and (max-width: 1550px) {
    background-position:-111px center;
  }
  @media screen and (max-width: 1280px) {
    background-position:-90px center;
  }
  @media screen and (max-width: 950px) {
    background-position:-150px center;
  }
}

.ellipsis {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.summary-text.about-me {
  @media screen and (max-width: 1550px) {
    width: 65%;
  }
  @media screen and (max-width: 1280px) {
    width: 70%;
  }
  @media screen and (max-width:950px) {
    width: 75%;
  }
  @media screen and (max-width:600px) {
    width: 100%;
  }
}

.banner-width-container {
  @media screen and (max-width: 1550px) {
    .newsroom-template-image {
      max-height: 375px!important;
    }
  }
  @media screen and (max-width: 1280px) {
    max-width: 860px;
    padding: 0 4rem;
    margin: 0 auto;

    .homepage-banner-img {
      width:100%!important;
      margin-top: 8rem;
    }
  }
}

.back-top-top {
  right: 16px;
  bottom: 16px;
  display: none;
}